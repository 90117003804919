
/*=============================================
=            notification            =
=============================================*/

.notification-item {
  padding: 20px 15px;

  text-align: right;

  border-bottom: 1px solid $grey--two;
  &__content {
    margin-bottom: 10px;
    .highlight {
      font-weight: 700;

      color: $black;
    }
  }
  &__time {
    font-size: 12px;
    line-height: 1;

    position: relative;

    padding-right: 15px;

    vertical-align: middle;
    span {
      position: absolute;
      top: 50%;
      right: 0;

      transform: translateY(-50%);
    }
  }

  &--unread {
    background-color: $blue--two;
  }
}

/*=====  End of notification  ======*/

