
/*=============================================
=            breadcrumb            =
=============================================*/

.breadcrumb-area {
  .page-title {
    font-size: 16px;
    font-weight: 700;

    text-align: right;

    color: $black--twelve;
  }
}


/*=====  End of breadcrumb  ======*/

